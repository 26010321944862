import { graphql } from 'gatsby';
import React from 'react';

import { Layout, SEO } from '../../components';

const TermsAndConditions = ({ data: { allMarkdownRemark } }) => {
  const toc = allMarkdownRemark.edges[0];

  return (
    <Layout bodyClass="standard-layout text-justify">
      <SEO
        title="YOU are safe online's terms and conditions"
        url="terms-and-conditions"
      />

      <section className="header-section bg-black">
        <div className="container">
          <h1>Terms and conditions</h1>
        </div>
      </section>

      <div className="container">
        <section
          className="body-section"
          dangerouslySetInnerHTML={{ __html: toc.node.html }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TermsAndConditionsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/terms-and-conditions/" } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`;

export default TermsAndConditions;
